<template>
  <el-container class="shopList">
    <div>
      <el-header class="box add">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>悦享每食</el-breadcrumb-item>
          <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        </el-breadcrumb>
      </el-header>
      <div style="padding: 0 20px 20px">
        <div>
          <!-- <el-form
            :inline="true"
            :model="query"
            class="demo-form-inline"
            style="margin-left: 10px"
          > -->
          <div class="df">
            <!-- <el-form-item size="medium" label-width="0"> -->
            <div style="flex: 0 0 300px">
              <el-input
                placeholder="桌位名称"
                v-model="query.keyword"
                class="input-with-select"
              >
              </el-input>
            </div>

            <!-- </el-form-item>
              <el-form-item size="medium" label-width="0"> -->
            <el-select
              style="margin-left: 20px"
              v-model="query.status"
              placeholder="请选择"
              @change="getList"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- </el-form-item> -->
            <!-- <el-form-item size="medium" label-width="0"> -->
            <el-button
              style="margin-left: 20px"
              type="primary"
              class="general_bgc general_border cfff"
              @click="getList"
              >查询
            </el-button>
            <el-button
              style="margin-left: 20px"
              type="primary"
              class="general_bgc general_border cfff"
              @click="clear"
              >清空搜索
            </el-button>
            <!-- </el-form-item> -->
          </div>
          <!-- </el-form> -->
        </div>
      </div>
      <div style="padding: 0 20px">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column type="index" label="序号" width="55">
          </el-table-column>
          <el-table-column prop="zwName" label="桌位名称" width="180">
          </el-table-column>
          <el-table-column prop="money" label="消费金额(元)" width="180">
          </el-table-column>
          <el-table-column prop="status" label="订单状态" width="180">
            <template slot-scope="scope">
              <p
                :style="
                  scope.row.status === '0000' ? 'color:green' : 'color:red'
                "
              >
                {{ scope.row.status === "0000" ? "已完成" : "用餐中" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="orderSn" label="订单编号" width="180">
          </el-table-column>
          <el-table-column prop="trxId" label="微信流水号" width="180">
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <!-- <el-button
                type="text"
                class="disib blue"
                size="mini"
                @click="handleShowInsider(scope.row)"
              >
                设置
              </el-button>
              <el-button
                type="text"
                class="disib blue"
                size="mini"
                @click="handleShowRecharge(scope.row.accountId)"
                >充值
              </el-button> -->
              <el-button
                type="text"
                class="disib blue"
                size="mini"
                @click="handleShowRechargeList(scope.row.id)"
              >
                菜品清单
              </el-button>
              <!-- <el-button
                type="text"
                class="disib red"
                size="mini"
                @click="handleShowReduce(scope.row.accountId)"
                >减扣
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="18"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange1"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <el-dialog title="设置" :visible.sync="showInsider" width="30%">
        <el-form
          :rules="rulesInsider"
          ref="insiderForm"
          :model="formData1"
          label-position="right"
          label-width="200px"
        >
          <el-form-item label="折扣是否生效:" prop="insider">
            <div style="float: left">
              <el-radio v-model="formData1.insider" :label="true">是</el-radio>
              <el-radio v-model="formData1.insider" :label="false">否</el-radio>
            </div>
          </el-form-item>
          <el-form-item label="打折(%):" prop="discount">
            <el-input
              placeholder="请输入打折"
              v-model="formData1.discount"
              type="number"
              clearable
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="操作密码:" prop="payPassword">
            <el-input
              placeholder="请输入操作密码"
              v-model="formData1.payPassword"
              type="number"
              clearable
              show-password
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 50px;
            "
          >
            <el-button type="info" size="medium" @click="showInsider = false"
              >取消</el-button
            >
            <el-button type="primary" size="medium" @click="handleInsider()"
              >确认</el-button
            >
          </div>
        </el-form>
      </el-dialog> -->
      <!-- <el-dialog title="充值" :visible.sync="showRecharge" width="30%">
        <el-form
          :rules="rules"
          ref="form"
          :model="formData"
          label-position="right"
          label-width="200px"
        >
          <el-form-item label="实充金额:" prop="money">
            <el-input
              placeholder="请输入充值金额"
              v-model="formData.money"
              type="number"
              clearable
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="赠送金额:" prop="welfareMoney">
            <el-input
              placeholder="请输入赠送金额"
              v-model="formData.welfareMoney"
              type="number"
              clearable
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="充值密码:" prop="payPassword">
            <el-input
              placeholder="请输入充值密码"
              v-model="formData.payPassword"
              type="number"
              clearable
              show-password
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 50px;
            "
          >
            <el-button type="info" size="medium" @click="showRecharge = false"
              >取消</el-button
            >
            <el-button type="primary" size="medium" @click="handleRecharge()"
              >确认充值</el-button
            >
          </div>
        </el-form>
      </el-dialog>
      <el-dialog title="减扣" :visible.sync="showReduce" width="30%">
        <el-form
          :rules="reduceRules"
          ref="reduceForm"
          :model="reduceData"
          label-position="right"
          label-width="200px"
        >
          <el-form-item label="减扣金额:" prop="money">
            <el-input
              placeholder="请输入减扣金额"
              v-model="reduceData.money"
              type="number"
              clearable
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="充值密码:" prop="payPassword">
            <el-input
              placeholder="请输入密码"
              v-model="payPassword"
              type="number"
              clearable
              show-password
              style="width: 60%; float: left"
            >
            </el-input>
          </el-form-item>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 50px;
            "
          >
            <el-button type="info" size="medium" @click="showReduce = false"
              >取消</el-button
            >
            <el-button type="primary" size="medium" @click="submitkj()"
              >确认减扣</el-button
            >
          </div>
        </el-form>
      </el-dialog> -->
      <el-dialog title="菜品清单" :visible.sync="showRechargeList" width="50%">
        <div
          class="df"
          v-if="
            flowId &&
            tableData.length &&
            tableData.filter((v) => v.id === flowId)[0].status === '0001'
          "
        >
          <div>
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="菜品">
                <el-cascader
                  v-model="chooseValue"
                  :options="goodsOptions"
                  @change="goodsChoose"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="数量">
                <el-input-number
                  v-model="goodsNum"
                  :min="1"
                  label="数量"
                ></el-input-number>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="margin-top: 40px"
                  type="primary"
                  @click="handleGoodsAdd"
                  >加菜</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="margin-bottom: 50px">
          <el-table
            border
            :data="rechargeList"
            style="width: 100%"
            height="650"
          >
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="goodsName" label="菜品" width="55">
            </el-table-column>
            <el-table-column prop="url" label="菜品" width="55">
              <template slot-scope="scope">
                <div style="width: 100px; height: 100px">
                  <img
                    :src="JSON.parse(scope.row.url)[0]"
                    style="width: 100%; height: 100%; object-fit: cover"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="weight" label="数量" width="55">
            </el-table-column>
            <el-table-column prop="price" label="小计(元)" width="55">
            </el-table-column>
            <el-table-column prop="url" label="操作" width="55">
              <template slot-scope="scope">
                <el-popover placement="right" width="360" trigger="click">
                  <div style="margin-top: 20px">
                    <el-form ref="form" label-width="80px">
                      <el-form-item label="减少数量">
                        <div>
                          <el-input-number
                            v-model="scope.row.reduceNum"
                            :min="1"
                            :max="scope.row.weight"
                            label="描述文字"
                          ></el-input-number>
                          <el-button
                            style="margin-left: 20px"
                            type="primary"
                            @click="handleGoodsReduce(scope.row)"
                            >减少</el-button
                          >
                        </div>
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-button
                    slot="reference"
                    type="text"
                    class="disib blue"
                    size="mini"
                  >
                    减少菜品
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <div
            class="df aic jca"
            style="margin-top: 40px"
            v-if="
              flowId &&
              tableData.length &&
              tableData.filter((v) => v.id === flowId)[0].status === '0001'
            "
          >
            <div>
              总计:{{ tableData.filter((v) => v.id === flowId)[0].money }}元
            </div>
            <el-popover placement="top" width="540" trigger="click">
              <div style="padding: 20px 20px 0">
                <el-form ref="form" label-width="80px">
                  <el-form-item label="充值密码:" prop="payPassword">
                    <el-input
                      placeholder="请输入密码"
                      v-model="payPassword"
                      type="number"
                      clearable
                      show-password
                      style="width: 60%; float: left"
                    >
                    </el-input>
                    <el-button
                      style="margin-left: 20px"
                      type="primary"
                      @click="endOrder"
                      >确认结束</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>

              <el-button slot="reference" type="primary">结束订单</el-button>
            </el-popover>
          </div>
        </div>
      </el-dialog>
      <!-- <el-dialog title="设置" :visible.sync="showSetting" width="30%">
        <div class="df jca mb20 f20 fwb">
          <div>门槛金额</div>
          <div>返现百分比</div>
        </div>
        <div
          v-for="(item, index) in setForm.shopWelfareDtos"
          :key="index"
          style="border-top: 1px solid #dcdcdc; padding: 10px"
          class="df jca pr"
        >
          <div>
            <el-input
              placeholder="请输入门槛金额"
              v-model="item.sillMoney"
              type="number"
              clearable
              style="width: 100%"
            >
            </el-input>
          </div>
          <div class="df aic">
            <el-input
              placeholder="请输入返现百分比"
              v-model="item.percent"
              type="number"
              clearable
              style="width: 100%"
            >
            </el-input>
            <div class="f25">
              <span>%</span>
            </div>
          </div>

          <div
            style="
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -15px;
              cursor: pointer;
            "
            class="df jca"
          >
            <div
              v-if="index === setForm.shopWelfareDtos.length - 1"
              @click="handleAdd(1)"
            >
              <i
                class="el-icon-circle-plus-outline"
                style="font-size: 30px; color: #409eff"
              ></i>
            </div>
            <div v-else>
              <i
                class="el-icon-circle-plus-outline"
                style="font-size: 30px; color: white"
              ></i>
            </div>
            <div v-if="index !== 0" @click="handleAdd(2, index)">
              <i
                class="el-icon-remove-outline"
                style="font-size: 30px; color: red"
              ></i>
            </div>
            <div v-else>
              <i
                class="el-icon-remove-outline"
                style="font-size: 30px; color: white"
              ></i>
            </div>
          </div>
        </div>
        <div
          style="display: flex; justify-content: space-around; margin-top: 50px"
        >
          <el-button type="info" size="medium" @click="showSetting = false"
            >取消</el-button
          >
          <el-button type="primary" size="medium" @click="submit"
            >确认</el-button
          >
        </div>
      </el-dialog> -->
      <!-- <el-dialog title="充值密码设置" :visible.sync="showSettingPd" width="30%">
        <el-form
          :rules="pdRules"
          ref="setPdForm"
          :model="setPdForm"
          label-position="right"
          label-width="200px"
        >
          <el-form-item label="原密码:" prop="money" v-if="hasPd">
            <el-input
              placeholder="请输入原密码"
              v-model="setPdForm.oldPayPassword"
              type="number"
              clearable
              style="width: 60%; float: left"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="money">
            <el-input
              placeholder="请输入新密码"
              v-model="setPdForm.payPassword1"
              type="number"
              clearable
              style="width: 60%; float: left"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item label="确认新密码:" prop="money">
            <div style="display: flex; flex-direction: column">
              <div>
                <el-input
                  placeholder="请确认新密码"
                  v-model="setPdForm.payPassword2"
                  type="number"
                  clearable
                  style="width: 60%; float: left"
                  show-password
                >
                </el-input>
              </div>
              <div
                style="font-size: 12px; color: red; text-align: left"
                v-show="showyz"
              >
                两次输入的密码不一致
              </div>
            </div>
          </el-form-item>
          <div
            style="
              display: flex;
              justify-content: space-around;
              margin-top: 50px;
            "
          >
            <el-button type="info" size="medium" @click="showSettingPd = false"
              >取消</el-button
            >
            <el-button type="primary" size="medium" @click="submitPd"
              >确认</el-button
            >
          </div>
        </el-form>
      </el-dialog> -->
    </div>
  </el-container>
</template>
<script>
import api from "../../../util/yxms-api";
import md5 from "js-md5";

export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (!this.formData.money && !this.formData.welfareMoney) {
        callback(new Error("请至少填写实充金额或赠送金额其中之一"));
      } else {
        this.$refs["form"].clearValidate();
        callback();
      }
    };
    return {
      tableData: [],
      currentPage: 1,
      allCurrentPage: 1,
      total: 0,
      query: {
        page: 1,
        limit: 18,
        companyId: null,
        keyword: "",
        status: "",
      },
      showInsider: false,
      showRecharge: false,
      showRechargeList: false,
      formData: {
        accountId: null,
        money: null,
        welfareMoney: null,
        companyId: null,
        payPassword: null,
        type: 0,
      },
      formData1: {
        accountId: null,
        insider: false,
        companyId: null,
        payPassword: null,
        discount: 0,
      },
      rulesInsider: {
        insider: [
          { required: true, message: "请选择是否内部人员", trigger: "blur" },
        ],
        discount: [
          { required: true, message: "请输入折扣信息", trigger: "blur" },
        ],
        payPassword: [
          { required: true, message: "请输入操作密码", trigger: "blur" },
        ],
      },
      rules: {
        money: [{ validator: validatePass, trigger: "blur" }],
        welfareMoney: [{ validator: validatePass, trigger: "blur" }],
        payPassword: [
          { required: true, message: "请输入充值密码", trigger: "blur" },
        ],
      },
      reduceRules: {
        money: [{ required: true, message: "请输入扣减金额", trigger: "blur" }],
      },
      pdRules: {},
      rechargeList: [],
      rechargeQuery: {
        accountId: null,
        companyId: null,
        page: 1,
        limit: 10,
      },
      rechargeTotal: 0,
      rechargeCurrentPage: 1,
      showSetting: false,
      showSettingPd: false,
      setForm: {
        shopWelfareDtos: [
          {
            sillMoney: null,
            percent: null,
          },
        ],
        companyId: null,
      },
      setPdForm: {
        oldPayPassword: null,
        payPassword1: null,
        payPassword2: null,
      },
      reduceData: {
        accountId: null,
        companyId: null,
        money: null,
        payPassword: null,
        type: 1,
      },
      showReduce: false,
      hasPd: false,
      showyz: false,
      flowId: null,
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0001",
          label: "用餐中",
        },
        {
          value: "0000",
          label: "已完成",
        },
      ],
      goodsOptions: [],
      goodsOptionsData: [],
      chooseValue: null,
      goodsNum: 1,
      goodsId: null,
      payPassword: null,
    };
  },
  mounted() {
    this.query.companyId = localStorage.getItem("company_id"); //获取当前企业
    this.setForm.companyId = localStorage.getItem("company_id"); //获取当前企业
    this.getList();
  },
  watch: {
    showRechargeList(v) {
      if (!v) {
        this.rechargeCurrentPage = 1;
        this.rechargeQuery = {
          accountId: null,
          companyId: null,
          page: 1,
          limit: 10,
        };
      }
    },
  },
  methods: {
    endOrder() {
      // console.log(
      //   {
      //     orderSn: this.tableData.filter((v) => v.id == this.flowId)[0].orderSn,
      //     money: this.tableData.filter((v) => v.id == this.flowId)[0].money,
      //     payPassword: md5(this.payPassword),
      //     companyId: this.query.companyId,
      //   },
      //   "测试"
      // );
      api
        .post("/v1/wx/userOrder/checkout", {
          orderSn: this.tableData.filter((v) => v.id == this.flowId)[0].orderSn,
          money: this.tableData.filter((v) => v.id == this.flowId)[0].money,
          payPassword: md5(this.payPassword),
          companyId: this.query.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getList();
            this.payPassword = "";
            this.$message.success("结账成功");
          }
        });
    },
    handleGoodsReduce(data) {
      let goodsList = [
        {
          goodsId: data.goodsId,
          weight: data.reduceNum,
        },
      ];
      // console.log(goodsList, 123);
      api
        .post("/v1/wx/allinPay/removeDishes", {
          goodsList,
          orderSn: this.tableData.filter((v) => v.id == this.flowId)[0].orderSn,
          companyId: this.query.companyId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.handleShowRechargeList(this.flowId);
          }
        });
    },
    handleGoodsAdd() {
      if (this.goodsId && this.chooseValue) {
        let goodsInfo = this.goodsOptionsData
          .filter((v) => v.id == this.chooseValue[0])[0]
          .goodsData.filter((v) => v.goodsId == this.chooseValue[1])[0];
        let data = {
          companyId: this.query.companyId,
          goodsId: goodsInfo.goodsId,
          goodsName: goodsInfo.goodsName,
          goodsWeight: Number((goodsInfo.goodsUnit * this.goodsNum).toFixed(2)),
          price: goodsInfo.unitPrice ? goodsInfo.unitPrice : goodsInfo.numPrice,
          weight: this.goodsNum,
        };
        api
          .post("/v1/wx/allinPay/addDishes", {
            goodsList: [data],
            orderSn: this.tableData.filter((v) => v.id == this.flowId)[0]
              .orderSn,
          })
          .then((res) => {
            if (res.code === 200) {
              this.goodsId = null;
              this.chooseValue = null;
              this.goodsNum = 1;
              this.handleShowRechargeList(this.flowId);
            }
          });
      }
    },
    goodsChoose(value) {
      // console.log(value, this.chooseValue);
      this.goodsId = value[1];
    },
    clear() {
      this.query.page = 1;
      this.query.status = "";
      this.query.keyword = "";
      this.getList();
    },
    submitkj() {
      this.$refs["reduceForm"].validate((valid) => {
        if (valid) {
          let data = {
            payPassword: md5(this.reduceData.payPassword),
            accountId: this.reduceData.accountId,
            money: this.reduceData.money,
            companyId: this.reduceData.companyId,
            type: this.reduceData.type,
          };
          api.post("/v1/wx/user/cz", data).then((res) => {
            if (res.code === 200) {
              this.showReduce = false;
              this.reduceData = {
                accountId: null,
                money: null,
                companyId: null,
                payPassword: null,
                type: 1,
              };
              this.getList();
            }
          });
        } else {
          return false;
        }
      });
    },
    submitPd() {
      if (this.setPdForm.payPassword1 !== this.setPdForm.payPassword2) {
        this.showyz = true;
        return;
      }
      this.showyz = false;
      this.$refs["setPdForm"].validate((valid) => {
        if (valid) {
          let data;
          if (this.hasPd) {
            data = {
              oldPayPassword: md5(this.setPdForm.oldPayPassword),
              payPassword: md5(this.setPdForm.payPassword2),
              companyId: this.query.companyId,
            };
          } else {
            data = {
              payPassword: md5(this.setPdForm.payPassword2),
              companyId: this.query.companyId,
            };
          }
          api.post("/v1/wx/user/szma", data).then((res) => {
            if (res.code === 200) {
              this.showSettingPd = false;
              this.setPdForm = {
                oldPayPassword: null,
                payPassword1: null,
                payPassword2: null,
              };
              // this.getList()
            }
          });
        } else {
          return false;
        }
      });
    },
    currentChange1(page) {
      this.currentPage = page;
      this.query.page = page;
      this.getList();
    },
    // currentChange2(page) {
    //   this.rechargeCurrentPage = page;
    //   this.rechargeQuery.page = page;
    //   this.handleShowRechargeList(this.rechargeQuery.id);
    // },
    submit() {
      api.post("/v1/wx/shopMember/mspz", this.setForm).then((res) => {
        if (res.code === 200) {
          this.showSetting = false;
          this.getList();
        }
      });
    },
    handleAdd(type, index) {
      if (type === 1) {
        this.setForm.shopWelfareDtos.push({
          sillMoney: null,
          percent: null,
        });
      } else {
        this.setForm.shopWelfareDtos.splice(index, 1);
      }
    },
    handleShowSetting() {
      api
        .get("/v1/wx/shopMember/mspzl", { companyId: this.query.companyId })
        .then((res) => {
          if (res.code === 200 && res.data.length > 0) {
            this.setForm.shopWelfareDtos = res.data;
          }
        });
      this.showSetting = true;
    },
    handleShowSettingPd() {
      api
        .get("/v1/wx/user/adminma", { companyId: this.query.companyId })
        .then((res) => {
          if (res.code === 200) {
            this.hasPd = res.data;
          }
        });
      this.showSettingPd = true;
    },
    getList() {
      api.get("/v1/wx/shop/flowList", this.query).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    handleInsider() {
      this.$refs["insiderForm"].validate((valid) => {
        if (valid) {
          let data = {
            payPassword: md5(this.formData1.payPassword),
            accountId: this.formData1.accountId,
            insider: this.formData1.insider,
            discount: this.formData1.discount,
            companyId: this.formData1.companyId,
          };
          api.post("/v1/wx/user/settingInsider", data).then((res) => {
            if (res.code == 200) {
              (this.showInsider = false),
                (this.formData1 = {
                  accountId: null,
                  insider: false,
                  companyId: null,
                  payPassword: null,
                  discount: 0,
                });
              this.getList();
            }
          });
        } else {
          return false;
        }
      });
    },
    handleRecharge() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let data = {
            payPassword: md5(this.formData.payPassword),
            accountId: this.formData.accountId,
            money: this.formData.money,
            companyId: this.formData.companyId,
            type: this.formData.type,
            zsMoney: this.formData.welfareMoney,
          };
          // console.log('data',data)
          api.post("/v1/wx/user/cz", data).then((res) => {
            if (res.code === 200) {
              this.showRecharge = false;
              this.formData = {
                accountId: null,
                money: null,
                companyId: null,
                payPassword: null,
                type: 0,
              };
              this.getList();
            }
          });
        } else {
          return false;
        }
      });
    },
    handleShowInsider(row) {
      this.formData1.accountId = row.accountId;
      this.formData1.companyId = this.query.companyId;
      this.formData1.insider = row.insider;
      this.formData1.discount = row.discount;
      this.showInsider = true;
    },
    handleShowRecharge(id) {
      this.formData.accountId = id;
      this.formData.companyId = this.query.companyId;
      this.showRecharge = true;
    },
    handleShowReduce(id) {
      this.reduceData.accountId = id;
      this.reduceData.companyId = this.query.companyId;
      this.showReduce = true;
    },
    handleShowRechargeList(id) {
      this.flowId = id;
      // console.log(id, "flowId");
      api
        .get("/v1/wx/shop/getFlowDetail", { flowId: this.flowId })
        .then((res) => {
          if (res.code === 200) {
            this.rechargeList = res.data.map((v) => {
              return {
                ...v,
                reduceNum: 1,
              };
            });
            this.rechargeTotal = res.total;
            this.showRechargeList = true;
            api
              .get("/v1/wx/shop/goods/list", {
                companyId: this.query.companyId,
              })
              .then((res1) => {
                this.goodsOptionsData = res1.data;
                this.goodsOptions = res1.data.map((v) => {
                  return {
                    value: v.id,
                    label: v.typeName,
                    children: v.goodsData.map((item) => {
                      return {
                        value: item.goodsId,
                        label: item.goodsName,
                        disabled: item.tag === 1,
                      };
                    }),
                  };
                });
              });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;

  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }

  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }

  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;

    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }

  /deep/ .el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}

$color: #2194e0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);

    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}

.activated {
  color: #1d953f;
}

.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}

.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  /*height: 20vw*/
}

.jca {
  justify-content: space-around;
}

.fwb {
  font-weight: bold;
}
</style>
